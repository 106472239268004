import {
  DesignMilestone,
  DesignMilestoneOrDraft,
  MilestoneType,
} from 'types/DesignMilestones';
import { ProjectMilestoneSummary } from 'types/Project';
import { DRAFT_PREFIX } from 'types/Risk';
import { sortMilestoneByDateFn } from 'utils/helpers';

export const getMilestoneName = (
  milestone?: DesignMilestone | ProjectMilestoneSummary,
) => {
  return milestone?.name;
};

export function isSavedMilestone(
  milestone: Pick<DesignMilestoneOrDraft, 'id'> | undefined,
): milestone is DesignMilestone {
  return Boolean(milestone && typeof milestone.id === 'number' && milestone.id > 0);
}
export function isMilestoneIdDraft(
  milestoneId: DesignMilestoneOrDraft['id'],
): milestoneId is string {
  return typeof milestoneId === 'string' && milestoneId.startsWith(DRAFT_PREFIX);
}

type Milestone = { id?: number; date: string | number; has_estimate?: boolean };

export const getMilestoneType = ({
  milestones,
  milestone,
}: {
  milestones: Milestone[];
  milestone: Milestone;
}): MilestoneType => {
  const sortedMilestones = milestones.toSorted(sortMilestoneByDateFn);
  let currentMilestone: Milestone | undefined;
  let forecastedMilestone: Milestone | undefined;

  sortedMilestones
    .filter((m) => m.id)
    .forEach((m) => {
      if (m.has_estimate && !forecastedMilestone) {
        currentMilestone = m;
      } else if (!m.has_estimate && !forecastedMilestone) {
        forecastedMilestone = m;
      }
    });
  if (currentMilestone && currentMilestone.id === milestone.id) {
    return 'current';
  }
  if (forecastedMilestone && forecastedMilestone.id === milestone.id) {
    return 'forecasted';
  }
  if (milestone.has_estimate) return 'has_estimate';
  return 'pending';
};
