import { useQuery } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { keyBy } from 'lodash-es';
import { DesignMilestone } from 'types/DesignMilestones';
import { useSelectedProjectId } from 'features/Projects/hook/useSelectedProjectId';
import { queryKeys } from 'utils/reactQuery';
import { useInvalidateTVDQueries } from 'features/TargetValueDesign/hooks/useInvalidateTVDQueries';
import { sortMilestoneByDateFn } from 'utils/helpers';
import { getMilestoneType } from 'utils/milestones';

export const useMilestones = ({
  enabled = true,
  projectId,
}: { enabled?: boolean; projectId?: number } = {}) => {
  const { selectedProjectId } = useSelectedProjectId();
  const invalidateTVDQueries = useInvalidateTVDQueries();

  const projectIdToUse = projectId ?? selectedProjectId;

  const onApiCallSuccess = () => {
    invalidateTVDQueries(projectIdToUse);
  };

  const milestonesQuery = useQuery({
    queryKey: queryKeys.project(projectIdToUse).milestones,
    queryFn: ({ signal }) => {
      const endPoint = Resources.ALL_MILESTONES_BY_PROJECT_ID.replace(
        '<int:project_pk>',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        projectIdToUse!.toString(),
      );
      return ApiService.get(endPoint, { signal }).then((res) => {
        onApiCallSuccess();
        const milestones = (res.data as DesignMilestone[]).toSorted(
          sortMilestoneByDateFn,
        );
        return milestones.map(
          (m, _, array) =>
            ({
              ...m,
              type: getMilestoneType({ milestones: array, milestone: m }),
            }) as DesignMilestone,
        );
      });
    },
    enabled: Boolean(projectIdToUse && enabled),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  const { data: milestones, ...rest } = milestonesQuery;

  const milestonesMapById = Array.isArray(milestones) ? keyBy(milestones, 'id') : {};

  return {
    milestones: milestones ?? [],
    milestonesMapById,
    ...rest,
  };
};
