import { ProjectMember } from './User';

export const DRAFT_PREFIX = 'new_';

export const mitigationsStatuses = ['DRAFT', 'IN_PROGRESS', 'COMPLETE'] as const;
export type MitigationStatus = (typeof mitigationsStatuses)[number];
export const mitigationStatusLabels: Record<MitigationStatus, string> = {
  DRAFT: 'Not Started',
  IN_PROGRESS: 'In progress',
  COMPLETE: 'Done',
} as const;

export type Mitigation = {
  id: number;
  name: string;
  description?: string;
  status: MitigationStatus;
  due_date?: string;
  leader: ProjectMember | null;
};

export type DraftMitigation = Omit<Mitigation, 'id'> & {
  id: string;
};

export type MitigationOrDraft = Mitigation | DraftMitigation;
export type RiskScoreLevel = 'None' | 'Low' | 'Medium' | 'High';

export type RiskCategory = {
  id: number;
  name: string;
};

export const riskStatuses = ['IDENTIFIED', 'CLOSED'] as const;
export type RiskStatus = (typeof riskStatuses)[number];
export const riskStatusLabels: Record<RiskStatus, string> = {
  IDENTIFIED: 'Identified',
  CLOSED: 'Closed',
} as const;

export type Risk = {
  id: number;
  number?: number | null;
  name: string;
  description?: string | null;
  status?: RiskStatus | null;
  owner_id?: number | null;
  owner?: {
    id: number;
    name: string;
    last_name: string;
    email: string;
  };
  value?: number | null;
  likelihood?: number | null;
  impact?: number | null;
  score?: number | null;
  category?: RiskCategory | null;
  category_id?: number | null;
  mitigations?: MitigationOrDraft[];
  date_created?: string;
  date_modified?: string;
};

export type DraftRisk = Omit<Risk, 'id' | 'mitigations'> & {
  id: string;
  mitigations?: MitigationOrDraft[];
};
export type RiskOrDraft = Risk | DraftRisk;
